export function emitEventV2(eventName: string, data: any) {
  const event = { eventName, data };
  const eventStr = JSON.stringify(event);
  // console.log(event);
  try {
    if (window["app"] && window["app"].postMessage) {
      window["app"].postMessage(eventStr);
    } else if (window["app"] && window["app"].callback) {
      window["app"].callback(eventStr);
    } else if (window.parent !== window && window.parent.postMessage) {
      window.parent.postMessage(eventStr, "*");
    } else if (
      window["ReactNativeWebView"] &&
      window["ReactNativeWebView"].postMessage
    ) {
      window["ReactNativeWebView"].postMessage(eventStr);
    } else if (window.webkit && window.webkit.messageHandlers) {
      webkit.messageHandlers.callback.postMessage(eventStr);
    }
  } catch (e) {
    console.log(e);
  }
}
