import { ProgramsProvider } from "../contexts/program";
import { RewardsProvider } from "../contexts/reward";
import { RouterProvider } from "../contexts/router";
import { SessionProvider } from "../contexts/session";
import { UserProvider } from "../contexts/user";
import { Main } from "./main";
import { FunctionalComponent, h } from "preact";
import { useEffect } from "preact/hooks";
import { ClientConfigurationsProvider } from "../contexts/client-configurations";

const App: FunctionalComponent = () => {
  useEffect(() => {
    console.log("v5.0.10");
  }, []);

  return (
    <div id="app">
      <SessionProvider>
        <UserProvider>
          <ClientConfigurationsProvider>
            <RouterProvider>
              <RewardsProvider>
                <ProgramsProvider>
                  <Main />
                </ProgramsProvider>
              </RewardsProvider>
            </RouterProvider>
          </ClientConfigurationsProvider>
        </UserProvider>
      </SessionProvider>
    </div>
  );
};

export default App;
