import { pluckValue } from "./conditional-operation";
import { withInternalError } from "./custom-errors";
import { replaceKeys } from "./regex";

export interface IOperand<IConstantType> {
  type_id: "CONSTANT" | "STATE_VALUE";
  constantValue?: IConstantType;
  stateValue?: (string | string[])[];
}

interface IADD {
  type_id: "ADD";
  operand: IOperand<number>;
}

interface ISubtract {
  type_id: "SUBTRACT";
  operand: IOperand<number>;
}

interface IDivide {
  type_id: "DIVIDE";
  operand: IOperand<number>;
  round?: boolean;
}

interface IMultiply {
  type_id: "MULTIPLY";
  operand: IOperand<number>;
}

interface IRound {
  type_id: "ROUND";
}

interface IPrepend {
  type_id: "PREPEND";
  operand: IOperand<string>;
}

interface IAppend {
  type_id: "APPEND";
  operand: IOperand<string>;
}

interface IReplace {
  type_id: "REPLACE";
  map: {
    [pattern: string]: IVariable;
  };
  fallbackText: string;
}

export type IOperation =
  | IADD
  | ISubtract
  | IMultiply
  | IDivide
  | IRound
  | IAppend
  | IPrepend
  | IReplace;

export interface IVariable {
  initialOperand: IOperand<any>;
  operations?: IOperation[];
}

export function getVariableValue(state: Object, variable: IVariable) {
  try {
    let result = null;
    let initialValue = null;
    if (variable.initialOperand.type_id === "CONSTANT") {
      initialValue = variable.initialOperand.constantValue;
    } else {
      initialValue = pluckValue(state, variable.initialOperand.stateValue);
    }

    result = initialValue;

    if (variable.operations && variable.operations.length) {
      for (let i = 0; i < variable.operations.length; i++) {
        const operation = variable.operations[i];
        if (operation.type_id === "REPLACE") {
          result = replaceVariablesInText(state, operation, result);
        } else if (operation.type_id === "ROUND") {
          result = Math.round(result);
        } else {
          let operand = null;
          if (operation.operand.type_id === "CONSTANT") {
            operand = operation.operand.constantValue;
          } else {
            operand = pluckValue(state, operation.operand.stateValue);
          }
          if (operation.type_id === "ADD") {
            result = result + operand;
          } else if (operation.type_id === "DIVIDE") {
            result = result / operand;
          } else if (operation.type_id === "MULTIPLY") {
            result = result * operand;
          } else if (operation.type_id === "SUBTRACT") {
            result = result - operand;
          }
        }
      }
    }

    if (result === undefined || result === null) {
      throw "invalid result";
      // throw withInternalError("invalid result obtained from variable", "getVariableValue", { state, variable })
    }

    return result;
  } catch (e) {
    // console.log(e);
    return undefined;
  }
}

export function replaceVariablesInText(
  state: Object,
  replace: IReplace,
  target: string
) {
  try {
    const variableValues: { [key: string]: any } = {};

    for (let key in replace.map) {
      variableValues[key] = getVariableValue(state, replace.map[key]);
    }

    return replaceKeys(target, variableValues);
  } catch (e) {
    console.log(e);
    return replace.fallbackText;
  }
}
